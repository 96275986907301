import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';
import handlePurchase from "@/services/modules/purchase";
import figureFormatter from "@/services/utils/figureFormatter";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const productionPDF = () => {
    const {getHeader, getFooter} = pdfHeaderFooter();
    const {currencyToWord} = handlePurchase();
    const { commaFormat } = figureFormatter();
    const exportToPDF = (company, production, barcode, qrcode, userName) => {

        const doc = {
            pageSize: 'A4',
            pageMargins: [25, 100, 25, 80],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(production, barcode),

            styles: {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Product Costing Report'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (production, barcode) => {
        return [
            {
                text: 'Production Voucher',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },
            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        stack: [
                            {text: `Costing Name: ${production.product_costing_master.name}`},
                            {text: `Batch No:: ${production.batch_no}`},
                            {text: `${'Manufacturing Date: ' + production.production_date}`},
                            {text: `${'Expairy date: ' + production.expire_date}`},
                            {text: `Total Production Cost: ${commaFormat(production.finished_goods_value)}`},
                            {text: `${production.contact_profile ? 'Customer Name: ' + production.contact_profile.name : ''}`},
                            {text: `${production.contact_buyer ? 'Buyer Name: ' + production.contact_buyer.name : ''}`}
                        ]
                    },
                    {
                        alignment: 'right',
                        stack: [
                            {svg: barcode},
                            {text: `${'Production Number: ' + production.bill_number}`},
                            {text: `${production.currency ? 'Currency: ' + production.currency : ''}`},
                        ]
                    },
                ]
            },
            ...generateGroupedTables(production.generals, production.finished_goods_location, production.raw_material_location),

            {
                margin: [0, 15],
                text: [

                ]
            },
            {
                stack: [
                    {text: `${production.description ? 'Note: ' : ''}`, bold: true},
                    {text: `${production.description ? production.description : ''}`}
                ]
            }
        ];
    };

    const createTable = (body, widths = ['*', '*'], fillRowIndex = 0, margin = [0, 10, 0, 0]) => ({
        style: 'tableExample',
        margin: margin,
        table: {
            widths: widths,
            body: body
        },
        layout: {
            fillColor: (rowIndex) => (rowIndex === fillRowIndex ? '#f3f2f7' : null),
            hLineWidth: () => 0.5,
            vLineWidth: () => 0.5,
        }
    });

    const generateGroupedTables = (generals, finishedGoodsLocation, rawMaterialLocation) => {
        const groupedByCategory = groupByCategory(generals);
        const tableContent = [];

        // Define the new order of categories
        const categoriesInOrder = ['production', 'materials', 'labor_and_overhead'];

        categoriesInOrder.forEach(category => {
            let locationName = ''
            if (category === 'production') {
                locationName = finishedGoodsLocation.name
            } else if (category === 'materials') {
                locationName = rawMaterialLocation.name
            }
            tableContent.push({
                columns: [
                    {
                        text: getCategoryName(category),
                        alignment: 'left',
                        style: 'tableHeading',
                        margin: [0, 10, 0, 0],
                        bold: true
                    },
                    {
                        text: locationName,
                        alignment: 'right',
                        style: 'tableHeading',
                        margin: [0, 10, 0, 0],
                        bold: true
                    }
                ]
            });

            const items = groupedByCategory[category] || [];

            tableContent.push(createTable(
                items.length > 0 ? getTableBody(items) : [[{ text: 'No data available', colSpan: getTableWidths(category).length, alignment: 'center' }]],
                getTableWidths(category),
                0,
                [0, 0, 0, 0]
            ));
        });

        return tableContent;
    };

    const getCategoryName = (category) => {
        switch (category) {
            case 'production':
                return 'Productions';
            case 'materials':
                return 'A. Raw Materials & B. Packing Materials';
            case 'labor_and_overhead':
                return 'C. Direct Labor & D. Direct Overhead';
            default:
                return 'Other Items';
        }
    };

    const groupByCategory = (items) => {
        return items.reduce((acc, item) => {
            let category;
            switch (item.type) {
                case 'production':
                    category = 'production';
                    break;
                case 'raw_materials':
                case 'packing_materials':
                    category = 'materials';
                    break;
                case 'direct_labor':
                case 'direct_overhead':
                    category = 'labor_and_overhead';
                    break;
                default:
                    category = 'other';
            }
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(item);
            return acc;
        }, {});
    };


    const getTableWidths = (type) => {
        if (type === 'production') {
            return ['*', '*', '*', '*', '*', '*'];
        }

        return ['*', '*', '*', '*'];
    };

    const getTableBody = (tableItems) => {
        const tableData = [];

        const header = getTableHead(tableItems[0].type);
        tableData.push(header);

        tableItems.forEach(item => {
            const row = getTableRow(item);
            tableData.push(row);
        });

        const totalAmount = calculateTotalAmount(tableItems);

        const footer = getTableFooter(totalAmount, tableItems[0].type);
        tableData.push(footer);

        return tableData;
    };

    const calculateTotalAmount = (tableItems) => {
        return tableItems.reduce((total, item) => total + parseFloat(item.amount || 0), 0);
    };

    const getTableFooter = (totalAmount, type) => {
        if (type === 'production') {
            return [
                {text: 'Total', colSpan: 4, alignment: 'center', bold: true}, {}, {}, {},
                {text: commaFormat(totalAmount), alignment: 'right', bold: true}, {},
            ];
        }

        return [
            {text: 'Total', colSpan: 3, alignment: 'center', bold: true}, {}, {},
            {text: commaFormat(totalAmount), alignment: 'right', bold: true}
        ];
    };

    const getTableHead = (type) => {
        if (type === 'production') {
            return [
                {text: 'Item Description'},
                {text: 'UoM', alignment: 'center'},
                {text: 'Total Qty', alignment: 'center'},
                {text: 'Cost %', alignment: 'center'},
                {text: 'Total Cost', alignment: 'right'},
                {text: 'Per Unit Cost', alignment: 'center'}
            ];
        }

        if (type === 'raw_materials' || type === 'packing_materials') {
            return [
                {text: 'Item Description'},
                {text: 'UoM', alignment: 'center'},
                {text: 'Total Qty', alignment: 'center'},
                {text: 'Amount', alignment: 'right'}
            ];
        }

        return [
            {text: 'Item Description'},
            {text: 'Total Qty', alignment: 'center'},
            {text: 'Rate', alignment: 'center'},
            {text: 'Amount', alignment: 'right'}
        ];
    };

    const getTableRow = (item) => {
        if (item.type === 'production') {
            return [
                {text: item.product_costingable.name},
                {text: item.unit_of_measurment, alignment: 'center'},
                {text: item.total_quantity, alignment: 'center'},
                {text: item.percentage, alignment: 'center'},
                {text: commaFormat(item.amount), alignment: 'right'},
                {text: item.rate, alignment: 'center'}
            ];
        }

        if (item.type === 'raw_materials' || item.type === 'packing_materials') {
            return [
                {text: item.product_costingable.name},
                {text: item.unit_of_measurment, alignment: 'center'},
                {text: item.total_quantity, alignment: 'center'},
                {text: commaFormat(item.amount), alignment: 'right'}
            ];
        }

        return [
            {text: item.product_costingable.name},
            {text: item.total_quantity, alignment: 'center'},
            {text: item.rate, alignment: 'center'},
            {text: commaFormat(item.amount), alignment: 'right'}
        ];
    };

    return {
        exportToPDF
    }
}

export default productionPDF;
